<template>
  <div class="container">
    <h2>Сотрудник: {{ fullName() }}</h2>
    <pre>{{ currentStaff }}</pre>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "employee-info",
  data() {
    return {
      id: this.$route.params["id"],
    };
  },
  created() {
    this.loadCurrentStaff(this.id);
  },
  computed: {
    ...mapState({
      currentStaff: (state) => state.staffStore.currentStaff,
    }),
    ...mapGetters({
      fullName: "staffStore/fullName",
    }),
  },
  methods: {
    ...mapActions({
      loadCurrentStaff: "staffStore/loadCurrentStaff",
    }),
  },
};
</script>
